import React from 'react'
import { numberWithCommas } from '../helpers/StringHelper'
import Currency, { currencyCb } from '../components/common/Currency'
import VentionLogoSm from '../partials/svgs/VentionLogoSm'

class Design {
  constructor(id, name, price, budget, currencyCode, currencySymbol) {
    this.id = id
    this.name = name
    this.price = price
    this.budget = budget
    this.currencyCode = currencyCode
    this.currencySymbol = currencySymbol
  }

  // Design request earning potential
  getEarningPotential() {
    return parseFloat(this.budget) * 0.025
  }

  getPrice(options = {}) {
    return currencyCb(this.price, options)
  }

  displayPrice() {
    return (
      <Currency
        symbol={this.currencySymbol}
        code={this.currencyCode}
        currencyCb={this.getPrice({ precision: 0 })}
      />
    )
  }

  displayEarningPotential() {
    return `$ ${numberWithCommas(this.getEarningPotential().toFixed(2))} USD`
  }

  static designPriceFilters = [
    { label: 'Under $500', value: [0, 500] },
    { label: '$500 to $1000', value: [500, 1000] },
    { label: '$1000 to $5000', value: [1000, 5000] },
    { label: '$5000 to $10000', value: [5000, 10000] },
    { label: '$10000 & above', value: [10000, null] },
  ]

  static assemblyTimeFilters = [
    { label: 'Under 5hrs', value: [0, 5] },
    { label: '5hrs to 10hrs', value: [5, 10] },
    { label: '10hrs to 20hrs', value: [10, 20] },
    { label: '20hrs to 30hrs', value: [20, 30] },
    { label: '30hrs & above', value: [30, null] },
  ]

  static designAuthors = [
    {
      id: 'official',
      logo: <VentionLogoSm />,
      label: 'OFFICIAL',
      backgroundColor: '#1c344f', // $vention-blue
    },
    {
      id: 'community',
      logo: 'https://assets.vention.io/page-assets/community/community-icon.svg',
      label: 'COMMUNITY',
      backgroundColor: '#f4b73a', // $info-yellow
    },
  ]

  static customCategories = {
    ALL: {
      code: 'ALL',
      label: 'All Vention Designs',
    },
    NEW: {
      code: 'NEW',
      html: '<span class="badge badge-secondary">New</span> Designs',
      label: 'New Designs',
    },
  }

  static sortOptions = [
    { label: 'Most recent', value: 'last_published' },
    { label: 'Most popular (all time)', value: 'popularity' },
    { label: 'Most popular (this month)', value: 'popularity_month' },
    { label: 'Price (high to low)', value: 'price_h_l' },
    { label: 'Price (low to high)', value: 'price_l_h' },
    { label: 'Most copied', value: 'most_copied' },
    { label: 'Most likes', value: 'likes' },
  ]

  static profileSortOptions = [
    { label: 'Last modified', value: 'last_modified' },
    { label: 'Last created', value: 'last_created' },
    { label: 'Price (high to low)', value: 'price_h_l' },
    { label: 'Price (low to high)', value: 'price_l_h' },
  ]

  static filterOptions = [
    { label: 'All designs', value: 'al' },
    { label: 'My team', value: 'cm' },
    { label: 'My designs', value: 'me' },
    { label: 'My collaborations', value: 'co' },
    { label: 'My public designs', value: 'pu' },
    // { label: 'My private designs', value: 'pr' } // Removed for #4408, keeping incase we want to add it back
  ]

  static dashboardFilterOptions = [
    { label: 'My team', value: 'cm' },
    { label: 'My designs', value: 'me' },
    { label: 'My collaborations', value: 'co' },
    { label: 'My public designs', value: 'pu' },
  ]

  static lifecycleOptions = [
    { label: 'In progress', value: 'progress' },
    { label: 'Under review', value: 'review' },
    { label: 'Ready to order', value: 'ready_to_order' },
    { label: 'Commissioned', value: 'comissioned' },
    { label: 'Archived', value: 'archived' },
  ]

  static computeCategoryOptions(categories) {
    const resultArray = [
      { label: 'All', value: 'ALL-ALL' },
      ...categories.map(category => ({
        label: category.name || '',
        value: category.full_code || '',
      })),
    ]
    return resultArray
  }

  static isEditableByUser = (design, user) =>
    design.collaborators.confirmed.some(c => c.id === user.id && ['c', 'lead'].includes(c.access))
}

export default Design
