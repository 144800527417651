import React from 'react'
import Modal from 'react-modal'
import classnames from 'classnames'

Modal.setAppElement('body')

interface Props {
  readonly minWidth?: string
  readonly maxWidth?: string | number
  readonly button: React.ReactHTMLElement<any> | React.DetailedHTMLProps<any, any>
  readonly header?: string
  readonly showCloseButton?: boolean
  readonly width?: string | number
  readonly children?: (callback: () => void) => React.ReactNode
  readonly modalClass?: string
}
interface State {
  readonly modalIsOpen: boolean
}

class ModalButton extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.makeButton = this.makeButton.bind(this)
  }

  makeButton = (buttonComponent: React.ReactHTMLElement<any>) =>
    React.cloneElement(buttonComponent, {
      onClick: event => this.openModal(event),
    })

  openModal(event) {
    event.preventDefault()
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  render() {
    const {
      minWidth = 'auto',
      button,
      header = '',
      showCloseButton = true,
      children = () => undefined,
      width = 'auto',
      maxWidth = 'auto',
    } = this.props

    const modalClasses = classnames('react-modal', this.props.modalClass)

    return (
      <React.Fragment>
        {this.makeButton(button)}
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={() => undefined}
          onRequestClose={this.closeModal}
          contentLabel='Example Modal'
          className={modalClasses}
          overlayClassName='react-modal-overlay'
          shouldCloseOnOverlayClick={true}
        >
          <div
            className='modal-content center-self modal-content-vertical-scroll'
            style={{ minWidth: minWidth, width: width, maxWidth: maxWidth }}
          >
            <div className='modal-header'>
              {showCloseButton ? (
                <button
                  type='button'
                  onClick={this.closeModal}
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true' className='icon-close'></span>
                </button>
              ) : null}
              {header}
            </div>
            {children(this.closeModal)}
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default ModalButton
