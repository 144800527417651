import classNames from 'classnames'
import React from 'react'

import { Link, LinkProps } from '../../routing/Router'

interface Props {
  readonly url?: string
  readonly viewItemRoute?: LinkProps
  readonly innerClassName: string
  readonly imageName: string
  readonly imgSrc: string
  readonly extraInfoAboveBody?: React.ReactNode
  readonly showOverlay?: boolean
  readonly overlayInfo?: React.ReactNode
  readonly simplifiedOverlayInfo?: React.ReactNode
  readonly extraInfoBelowOverlay?: React.ReactNode
  readonly name: string
  readonly number?: string
  readonly authorImgSrc?: string
  readonly authorName: string
  readonly authorUrl: string
  readonly extraInfoBelowAuthor?: React.ReactNode
  readonly extraAuthorDetails?: React.ReactNode
  readonly price?: number
  readonly extraPriceInfo?: React.ReactNode
  readonly mobileNode?: React.ReactNode
  readonly deploymentBundle?: boolean
}

/**
 * Pure component used by Design and Design Request Cards.
 * At the moment, the class names are specific to design cards.
 * Might be a good idea to make it reusable for Part Cards as well.
 */
const Card: React.FC<Props> = ({
  url,
  viewItemRoute,
  innerClassName,
  imageName,
  imgSrc,
  extraInfoAboveBody,
  showOverlay,
  overlayInfo,
  simplifiedOverlayInfo,
  extraInfoBelowOverlay,
  name,
  number,
  authorImgSrc,
  authorName,
  authorUrl,
  extraInfoBelowAuthor,
  extraAuthorDetails,
  price,
  extraPriceInfo,
  mobileNode,
  deploymentBundle,
}) => {
  return (
    <div className='design-card' data-design-number={number}>
      {viewItemRoute ? (
        <Link {...viewItemRoute} className='design-card-mobile-link-overlay'></Link>
      ) : url ? (
        <a className='design-card-mobile-link-overlay' href={url}></a>
      ) : (
        mobileNode
      )}
      <div className={innerClassName}>
        <img alt={imageName} src={imgSrc} title={imageName} />
        {extraInfoAboveBody}

        {!simplifiedOverlayInfo && (
          <div className='design-card-overlay'>
            <div className='grid'>{overlayInfo}</div>
            {extraInfoBelowOverlay}
          </div>
        )}
      </div>
      {number ? (
        <div
          className={classNames('design-card-body', { 'design-card-body--dark': deploymentBundle })}
        >
          {viewItemRoute ? (
            <Link {...viewItemRoute} className='design-card-name'>
              {name}
            </Link>
          ) : url ? (
            <div className='design-card-name-wrapper'>
              {deploymentBundle && <i className='icon-launch'></i>}
              <a className='design-card-name' href={url} data-toggle='popover' data-content={name}>
                {name}
              </a>
            </div>
          ) : (
            name
          )}
          <div className='design-card-number'>{number}</div>
          <div className='design-card-footer'>
            <div className='design-card-author'>
              <div className='design-card-author-img-wrapper'>
                {authorImgSrc && (
                  <img
                    className='design-card-author-img'
                    src={authorImgSrc}
                    alt={`Avatar of ${authorName}`}
                  />
                )}
                {extraInfoBelowAuthor}
              </div>
              <div className='design-card-author-name'>
                <a href={authorUrl} title={authorName}>
                  {authorName}
                </a>
                <div className='design-card-author-details'>
                  {extraAuthorDetails}
                  {price && (
                    <div className='design-card-price'>
                      {price}
                      {extraPriceInfo}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='design-card-body'>
          {name}
          {!simplifiedOverlayInfo && (
            <div className='design-card-price'>
              {price}
              {extraPriceInfo}
            </div>
          )}
        </div>
      )}
      {simplifiedOverlayInfo && showOverlay && (
        <div className='design-card-overlay'>
          {simplifiedOverlayInfo}
          {extraInfoBelowOverlay}
        </div>
      )}
    </div>
  )
}

export default Card
