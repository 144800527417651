import React from 'react'
import DesignCardCollaborators from './DesignCardCollaborators'
import DesignCardWrapper from './DesignCardWrapper'
import DesignVisibility from './DesignVisibility'
import ModalButton from '../common/ModalButton'
import DesignCustomizationModal from '../../partials/designs/DesignCustomizationModal'
import DesignModel from '../../models/DesignModel'
import { numberWithCommas } from '../../helpers/StringHelper'
import Card from '../common/cards/Card'
import DesignAddToCartButton from './DesignAddToCartButton'

const DesignCardClass = props => {
  const {
    id,
    name,
    image_thumb_path,
    design_number,
    likes_count,
    copies_count,
    collaborators,
    lead_designer = {},
    visibility_level,
    contain_motion_code,
    showStats = true,
    is_request = false,
    editable,
    showOverlay = true,
    currentUser = {},
    total_price,
    url,
    version,
    openDesignModal,
    budget,
    updateDesignState,
    currency_code,
    currency_symbol,
    deployment_bundle_id,
    contact_only_url,
  } = props
  const designInstance = new DesignModel(
    id,
    name,
    total_price,
    budget,
    currency_code,
    currency_symbol
  )
  const price = is_request
    ? designInstance.displayEarningPotential()
    : designInstance.displayPrice()
  // URL to view the design
  const viewDesignUrl = `/designs/${url ? `${url}-${id}` : id}`

  // URL to open the design in the CAD
  const openDesignUrl = `/machine-builder/${id}`

  // URL to view the design request
  const viewRequestUrl = `/requests/${id}`

  return (
    <Card
      url={is_request ? viewRequestUrl : viewDesignUrl}
      innerClassName={is_request ? 'design-request-card-img' : ' design-card-img'}
      imageName={name}
      imgSrc={image_thumb_path}
      extraInfoAboveBody={
        <>
          {contain_motion_code && <div className='design-card-programmed-banner'>Programmed</div>}
        </>
      }
      showOverlay={showOverlay}
      overlayInfo={
        <>
          {is_request ? (
            <a className='button button-block button-white-outline' href={viewRequestUrl}>
              View Details
            </a>
          ) : (
            <React.Fragment>
              {editable ? (
                <a className='button button-block button-white-outline' href={openDesignUrl}>
                  Open 3D Design
                </a>
              ) : (
                <a className='button button-block button-white-outline' href={viewDesignUrl}>
                  View Design
                </a>
              )}

              {contact_only_url ? (
                <a
                  className='button button-block button-white-outline'
                  href={contact_only_url}
                  data-turbo='true'
                  data-turbo-frame='turbo_modal_portal'
                >
                  Contact Us
                </a>
              ) : (
                <DesignAddToCartButton
                  isCollab={false}
                  currentUserId={currentUser.id}
                  design={props}
                  addToCartButtonClass='button button-block button-white-outline'
                />
              )}

              {editable && !is_request && (
                <ModalButton
                  button={
                    <button className='button button-block button-white-outline'>
                      Request Design Review
                    </button>
                  }
                  header='Request Design Help'
                  showCloseButton={true}
                  width='720px'
                >
                  {closeModal => (
                    <DesignCustomizationModal
                      closeModal={closeModal}
                      design={{
                        id: id,
                        design_number: design_number,
                        name: name,
                        version: version,
                        is_collaborator: true,
                      }}
                      updateDesignState={updateDesignState}
                    />
                  )}
                </ModalButton>
              )}
            </React.Fragment>
          )}
        </>
      }
      extraInfoBelowOverlay={
        editable &&
        !is_request && (
          <button
            className='design-card-edit button button-blank'
            onClick={() => openDesignModal('n')}
          >
            <i className='fal fa-edit' />
          </button>
        )
      }
      name={name}
      number={design_number}
      authorImgSrc={lead_designer.avatar_url}
      authorName={lead_designer.display_name}
      authorUrl={`/users/${lead_designer.id}`}
      extraInfoBelowAuthor={
        editable && (
          <DesignCardCollaborators
            currentUser={currentUser}
            collaborators={collaborators}
            addCollaborators={() => openDesignModal('c')}
          />
        )
      }
      extraAuthorDetails={
        visibility_level == 'a' ? (
          <div className='text-muted'>Score: {numberWithCommas(lead_designer.rep_score)}</div>
        ) : (
          <DesignVisibility visibilityLevel={visibility_level} />
        )
      }
      price={price}
      extraPriceInfo={is_request && <div className='design-card-earning'>Earning potential</div>}
      deploymentBundle={!!deployment_bundle_id}
    />
  )
}

const DesignCard = DesignCardWrapper(DesignCardClass)
export const DesignCardStatic = DesignCardClass // Stateless non-functional design card
// The wrapped card which performs state fetches must be the default
// This is because ReactRailsUJS cannot access non-default component constructors
// And that component constructor is passed into the assembler through an ERB template using such a constructor
export default DesignCard
