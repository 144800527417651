import { isEmpty } from '../../helpers/StringHelper'
const DesignCardCollaborators = props => {
  const { collaborators = { confirmed: [], pending: [] }, addCollaborators, currentUser } = props
  const content = collaborators.confirmed
    .filter(collaborator => currentUser && collaborator.id != currentUser.id)
    .map((collaborator, index) => (
      <li key={index}>
        <a className='design-card-collaborator' href={`/users/${collaborator.id}`}>
          <img
            className='design-card-collaborator-img'
            src={
              collaborator.avatar_url
                ? collaborator.avatar_url
                : 'https://assets.vention.io/png/avatars/default_avatar.png'
            }
            alt='Collaborator'
          />
          {isEmpty(collaborator.first_name) || isEmpty(collaborator.last_name)
            ? 'Guest'
            : `${collaborator.first_name} ${collaborator.last_name}`}
        </a>
      </li>
    ))

  return (
    <div className='design-card-collaborators'>
      <ul className='list-unstyled'>{content}</ul>
      <button className='button button-block button-success' onClick={addCollaborators}>
        <i className='fas fa-plus i-left'></i>
        Add Collaborators
      </button>
    </div>
  )
}

export default DesignCardCollaborators
