import React from 'react'
import DesignService from '../../api/Designs/Service'
import SiteInfoStore from '../../stores/SiteInfoStore'
import { numberWithCommas } from '../../helpers/StringHelper'

const DesignCardWrapper = Component => {
  return class WrappedDesignCard extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        currentUser: props.currentUser,
        editable: props.editable ? props.editable : false,
        design: props.design,
      }

      this.openDesignModal = this.openDesignModal.bind(this)
    }

    componentDidMount() {
      $('[data-toggle="popover"]').popover({
        trigger: 'hover',
        container: 'body',
        placement: 'bottom',
      })
      const currentUserIsLeadDesigner = user =>
        user && user.id === this.state.design.lead_designer_id
      const currentUserIsCollaborator = user =>
        this.state.design.collaborators &&
        this.state.design.collaborators.confirmed.filter(
          collab => collab.id == (user || {}).id // user can null because not logged in
        ).length > 0
      // Fetch current user and determine if they own the design
      if (
        Object.prototype.hasOwnProperty.call(this.props.design, 'lead_designer') &&
        !this.state.currentUser
      ) {
        SiteInfoStore.getCurrentUser(updatedUser => {
          this.setState({
            currentUser: updatedUser,
            editable:
              currentUserIsLeadDesigner(updatedUser) || currentUserIsCollaborator(updatedUser),
          })
        })
      } else {
        const editable =
          currentUserIsLeadDesigner(this.state.currentUser) ||
          currentUserIsCollaborator(this.state.currentUser)
        this.setState({ editable: editable })
      }
      if (this.state.design.id && Object.keys(this.state.design).length === 1) {
        DesignService.fetchOneDesign(this.state.design.id, design =>
          this.setState({ design: design })
        )
      }
    }

    openDesignModal(tab) {
      let newCopyDesign = this.state.design
      if (tab == 'c' && this.props.design.collaborators.adding.length === 0) {
        newCopyDesign = JSON.parse(JSON.stringify(this.props.design))
        newCopyDesign.collaborators.adding.push({
          id: 1,
          email: '',
          access: 'c',
        })
      }
      editAssemblyCardInstance.setState({
        design: newCopyDesign,
        tab: tab,
        newAssembly: false,
        company_id: this.state.currentUser.company_id,
        company_domain: this.state.currentUser.email.substr(
          this.state.currentUser.email.indexOf('@') + 1
        ),
        currentUserId: this.state.currentUser.id,
        sharable_link: '',
      })
      editAssemblyCardInstance.setCallback(updatedDesign => {
        if (undefined != updatedDesign) {
          this.setState({ design: updatedDesign })
        }
        if (this.props.updateDesignState) {
          this.props.updateDesignState(updatedDesign)
        }
      })
      $('#edit_assembly_card').modal('show')
    }

    getDesignPrice() {
      return numberWithCommas(parseFloat(this.state.design.total_price).toFixed(2))
    }

    render() {
      return (
        <Component
          currentUser={this.state.currentUser}
          editable={this.state.editable}
          openDesignModal={this.openDesignModal}
          isRequestView={this.props.isRequestView}
          showStats={this.props.showStats}
          showOverlay={this.props.showOverlay}
          {...this.state.design}
          {...this.props}
        />
      )
    }
  }
}

export default DesignCardWrapper
