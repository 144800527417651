import React from 'react'
function displayContent(visibilityLevel) {
  if (visibilityLevel === 'p' || visibilityLevel === 'private_level') {
    return (
      <div
        className='design-visibility'
        title='Only you and other collaborators can see this design'
      >
        <i className='fa fa-lock i-left-space' />
        (Private)
      </div>
    )
  } else if (visibilityLevel === 'c') {
    return (
      <div className='design-visibility' title='Only you and your team can see this design'>
        <i className='fa fa-lock i-left-space' />
        (Team)
      </div>
    )
  } else {
    return <div className='design-visibility' />
  }
}
const DesignVisibility = props => {
  const { visibilityLevel } = props
  return <React.Fragment>{displayContent(visibilityLevel)}</React.Fragment>
}

export default DesignVisibility
